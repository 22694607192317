/* Google Tag Manager */
function gtmTracker(id) {
	(function(w,d,s,l,i) {
		w[l] = w[l] || [];
		w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js'
		});
		var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l:'';
		j.async = true;
		j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
		f.parentNode.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer', id);
}

/* Only load GTM when not logged-in and in production environment */
if ((!window.wp.wp_env || window.wp.wp_env === 'production') && !window.wp.is_admin) {
	gtmTracker(window.wp.gtm_id);
} else {
	console.log('GTM:', window.wp.gtm_id);
}
